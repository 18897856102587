import { LSFetch } from '../helpers'

export const getBlogById = (id) => {
    return LSFetch.get(`/blogs/retrieve/blogs/${id}`);
}

export const getBlogByTitle = async (title) => {
    //If trying to reach blog with a number, retrieve its urlSafeTitle with its ID and redirect.
    if (!isNaN(title) && Number.isInteger(Number(title))) {
        try {
            const response = await getBlogById(title);
            var urlSafeTitle = await response.json.urlSafeTitle;
            // Redirect to blog fetched with title
            if (urlSafeTitle) {
                window.location.href = window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/") + 1) + encodeURIComponent(urlSafeTitle);
            } else {
                window.location.href = window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/") + 1)
            }
        } catch (error) {
            console.error("Error fetching blog by ID:", error);
            throw error;
        }
    }
    return LSFetch.get(`/blogs/retrieve/blog/${title}`);
}

export const getAllBlogs = (pageNumber, language) => {
    return LSFetch.get(`/blogs/get/all/blogs/${pageNumber}`,{ params: { language } })
}
