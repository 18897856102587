import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Currency from 'react-currency-formatter';
import { spacing, colors, fonts } from '../../../../../defaults/styles';
import { withLocalize } from 'react-localize-redux';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';
import GridLoader from 'react-spinners/GridLoader';
import AddToCartButton from '../../../pages/CatalogPage/Cart/AddToCartButton';
import AddToShoppingListButton from '../../../pages/CatalogPage/ShoppingList/AddToShoppingListButton';
import ZoomShareButton from '../../../pages/ProductPage/Product/Content/ZoomCatalog/ZoomShareButton.js';
import {
  createSingleZoomProject,
  ZOOM_REFERER
} from '../../../../../tools/api/zoomCatalog.js';
const imageSize = 220;
const imageLoggedInHomeSize = 'auto';
const imageSizeMobile = 'auto';
const imageLogoSize = 50;
const imageLogoSizeMobile = 35;
const discontinuedElemTabletSize = 150;

const Product = ({
  activeLanguage,
  product,
  loggedInHome = false,
  isMobile,
  isTablet,
  translate,
  currentQuery,
  shoppingLists,
  cart,
  history,
  showPopup,
  setShowPopup,
  zoomStudioItToken,
  zoomStudioUserToken,
  errorMessage,
  setErrorMessage,
  appendErrorMessage,
  setEditUrl,
  setViewUrl,
}) => {
  const [hovering, setHovering] = useState(false);

  const lang =
    activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';

  const productDiscontinued = product.statusId === 3;
  const productDisabled = product.statusId === 4;

  const linkStyle = isMobile
  ? {
      display: 'flex',
      flexDirection: 'column',
      width: imageSizeMobile,
      textDecoration: 'none',
      textAlign: 'center',
      paddingBottom: spacing.medium,
      color: colors.black.normal,
      borderColor:
        hovering && !productDiscontinued
          ? colors.primary.normal
          : colors.gray.light,
      position: 'relative',
      height: '100%',
      cursor: 'pointer',
    }
  : {
      display: 'flex',
      flexDirection: 'column',
      width: imageSize,
      textDecoration: 'none',
      textAlign: 'center',
      paddingBottom: spacing.medium,
      color: hovering ? colors.primary.normal : colors.black.normal,
      borderColor: hovering
        ? colors.primary.normal
        : colors.gray.light,
      position: 'relative',
      height: '100%',
      cursor: 'pointer',
    };

    const fixEditUrl = (editUrl) => {
      try {
        // Check if the URL contains "localhost:3000"
        if (editUrl.includes('localhost:3000')) {
          // Replace "localhost:3000-quickcanvas" with "loyaltysource-quickcanvas"
          return editUrl.replace('localhost:3000-quickcanvas', ZOOM_REFERER);
        }
        // Check if the URL contains "preprod.loyaltysource.com"
        if (editUrl.includes('preprod.loyaltysource.com')) {
          // Replace "preprod.loyaltysource.com-quickcanvas" with "loyaltysource-quickcanvas"
          return editUrl.replace('preprod.loyaltysource.com-quickcanvas', ZOOM_REFERER);
        }
        // Also change in production environment
        if (editUrl.includes('www.loyaltysource.com')) {
          // Replace "loyaltysource.com-quickcanvas" with "loyaltysource-quickcanvas"
          return editUrl.replace('www.loyaltysource.com-quickcanvas', ZOOM_REFERER);
        }
        return editUrl;
      } catch (error) {
        console.error('Error while fixing edit_url:', error.message);
        return editUrl;
      }
    }

    const generateDesign = async (sku) => {
      createSingleZoomProject(zoomStudioUserToken ? zoomStudioUserToken : zoomStudioItToken, sku)
        .then(data => {
          const fixedEditUrl = fixEditUrl(data.edit_url);
  
          setEditUrl(fixedEditUrl);
          setViewUrl(data.viewer_url);
        })
        .catch(error => {
          console.error('Error while generating zoom project:', error);
          appendErrorMessage('Error while generating zoom project:', error);
        });
    }
  
    const handleShareOnClick = (sku) => {
      generateDesign(sku);
      setShowPopup(true);
    }

  return (
    <>
      <div
        style={linkStyle}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <ProductSection 
          isMobile={isMobile}
          isTablet={isTablet}
          loggedInHome={loggedInHome}
          product={product}
          translate={translate}
          currentQuery={currentQuery}
          lang={lang}
          history={history}
          handleShareOnClick={handleShareOnClick}
        />
      </div>
      {loggedInHome && product.msrp && (<>{!cart || !shoppingLists  ? 
        (<GridLoader
            css={{ margin: 'auto', height: '1rem', overflowY:'hidden' }}
            size={12}
            color={colors.main.normal}/>
        )
      :(<div style={{ 
            // display: 'flex', 
            // flexDirection: !isMobile ? 'row' : 'column-reverse'
          }}
        >
          {!productDisabled && <AddToCartButton isMobile={isMobile} sku={product.sku} msrp={product.msrp} discontinued={productDiscontinued} />}
        </div>
        )
      }</>) }
    </>
  );
};

function mapStateToProps({ cart, shoppingLists }) { return { cart, shoppingLists }; }
const mapDispatchToProps = { };

export default withRouter(withLocalize(connect(
    mapStateToProps,
    mapDispatchToProps,
) (Product)));

const ProductSection = ({ 
  isMobile, 
  isTablet, 
  loggedInHome, 
  product: { sku, name, imageUrl, brand, brandImageUrl, statusId, msrp, price, isCanadian }, 
  translate,
  currentQuery,
  lang,
  history,
  handleShareOnClick
}) => {

  const [noDiscount, setNoDiscount] = useState(false);
  const t = (tag) => `productList.${tag}`;
  useEffect( () => {
    if (price >= msrp) setNoDiscount(true);
  }, [msrp, price]);

  const fromLocation = new URL(window.location.href).pathname.split("/").pop();

  const createSlug = (url) =>
     url
        .normalize("NFD") // Normalize accented characters
        .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
        .replace(/[^a-zA-Z0-9\s]/g, "") // Remove special characters
        .trim()
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .toLowerCase();

  const proceedToProduct = () => history.push({pathname: `/${lang}/product/${encodeURIComponent(createSlug(name))}/${sku}`, state: { from: fromLocation }});


  return <>
      <ProductImageFilter
          isMobile={isMobile}
          isTablet={isTablet}
          loggedInHome={loggedInHome}
      >
        <ProductImage src={imageUrl} alt={name} lang={lang} onClick={proceedToProduct} />
        {statusId === 3 && (
          <DiscontinuedFilter isMobile={isMobile} isTablet={isTablet} onClick={proceedToProduct}>
            <DiscontinuedFilterInner isMobile={isMobile} isTablet={isTablet}>
              {translate(t('discontinued'))}
            </DiscontinuedFilterInner>
          </DiscontinuedFilter>
        )}
        <BrandImage src={brandImageUrl} alt={brand} onClick={proceedToProduct} />
      {isCanadian && <MapleLeafWrapper>
        <Tooltip className="tooltip">{translate(t('canadaBrand'))}</Tooltip>
        <MapleLeafImage src={'/images/maple_leaf_transparent.png'} alt={'Canada Leaf'} onClick={proceedToProduct} />
      </MapleLeafWrapper>}
        {statusId !== 3 && (
        <ButtonContainer>
          <ZoomShareButton
            sku={sku}
            handleShareOnClick={handleShareOnClick}
          />
          <AddToShoppingListButton 
            isMobile={isMobile} 
            sku={sku} 
            currentQuery={currentQuery} 
            style={{left: '0.5rem'}}
          />
        </ButtonContainer>
        )}
      </ProductImageFilter>

      <ProductNameLabel onClick={proceedToProduct}>{name}</ProductNameLabel>
      <SkuLabel onClick={proceedToProduct}>{sku}</SkuLabel>
      {loggedInHome && msrp && (
        <span
          onClick={proceedToProduct}
          style={{
            color: '#555',
            fontWeight: 500,
            display: 'grid',
            gridTemplateColumns: isMobile ? '1fr' : 'auto 1fr',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'start',
            listStyle: 'none',
            fontSize: '1rem',
            marginTop: isMobile ? '1em' : '0.5em',
          }}
        >
          <span
            onClick={proceedToProduct}
            style={{
              color: !noDiscount ? colors.primary.normal : '#555',
              fontWeight: !noDiscount ? null : 'bold',
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            <Currency quantity={price} currency="CAD" locale={lang} />
          </span>
          <span
            onClick={proceedToProduct}
            style={{
              borderRadius: '20px',
              fontSize: '0.8rem',
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {translate(t('msrp'))}
            <span style={{
                textDecoration: !noDiscount ? 'line-through' : null,
              }}>
              <Currency quantity={msrp} currency="CAD" locale={lang} />
            </span>
          </span>
        </span>
      )}
    </>;
}

const ProductImage = styled.img`
  width: 100%;
  height: 100%;
`;

const BrandImage = styled.img`
  width: ${props => (props.isMobile ? imageLogoSizeMobile : imageLogoSize)}px;
  height: ${props => (props.isMobile ? imageLogoSizeMobile : imageLogoSize)}px;
  box-shadow: -1px 1px 0px 0px rgba(239, 239, 239, 1);
  display: block;
  position: absolute;
  background-color: white;
  top: 1px;
  right: 1px;
`;

const MapleLeafImage = styled.img`
  width: ${props => (props.isMobile ? imageLogoSizeMobile : imageLogoSize)}px;
  height: ${props => (props.isMobile ? imageLogoSizeMobile : imageLogoSize)}px;
  display: block;
`;

const MapleLeafWrapper = styled.div`
  position: absolute;
  bottom: 1px;
  right: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  bottom: 100%;
  background-color: black;
  color: white;
  text-align: center;
  padding: 5px 10px;
  right: -10px;
  border-radius: 5px;
  font-size: ${fonts.sizes.smallText}px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
`;

const SkuLabel = styled.span`
  margin-top: ${spacing.small/2}px;
  color: inherit;
  display: block;
  font-size: ${fonts.sizes.smallText}px;
  text-align: left;
`;

const ProductNameLabel = styled.span`
  color: inherit;
  flex-grow: 1;
  display: block;
  font-weight: bold;
  font-size: ${fonts.sizes.text}px;
  line-height: 1.2;
  margin-top: ${spacing.verySmall}px;
  height: 100%;
  text-align: left;
`;

const ProductImageFilter = styled.span`
  width: ${props =>
    props.isMobile
      ? imageSizeMobile
      : props.loggedInHome
      ? imageLoggedInHomeSize
      : imageSize + 'px'};
  display: block;
  border: 1px solid;
  border-color: inherit;
  position: relative;
`;

const DiscontinuedFilter = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  width: ${props =>
    props.isMobile
      ? '100%'
      : props.isTablet
      ? discontinuedElemTabletSize + 'px'
      : imageSize - 2 + 'px'};
  height: ${props =>
    props.isMobile
      ? '100%'
      : props.isTablet
      ? discontinuedElemTabletSize + 'px'
      : imageSize - 2 + 'px'};
  position: absolute;
  top: ${props => (props.isMobile ? '50%' : '1px')};
  left: ${props => (props.isMobile ? '50%' : '1px')};
  transform: ${props => (props.isMobile ? 'translate(-50%, -50%)' : null)};
  z-index: 5;
`;

const DiscontinuedFilterInner = styled.div`
  background-color: ${colors.main.normal};
  color: ${colors.white.normal};
  font-size: ${fonts.sizes.small * 1.4}px;
  padding: 7px;
  height: 'auto';
  width: ${props => (props.isMobile || props.isTablet ? '100%' : 'undefined')};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spacing.small}px;
  position: absolute;
  bottom: 10px; 
  left: 10px;
  display-direction: row;
`;
