import qs from 'querystring';

let url = `http://localhost:8420/api`;

if (process.env.REACT_APP_STAGING_ENV) {
  if (process.env.REACT_APP_STAGING_ENV === 'production') {
    url = `/api`;
  } else if (process.env.REACT_APP_STAGING_ENV === 'preprod') {
    url = `/api`;
  } else {
    url = `http://localhost:8420/api`;
  }
}

const getLsURL = path => {
  return `${url}${path}`;
};

const formatFetch = async stream => {
  if (stream.redirect) {
    window.location.href = stream.redirect;
  } else {
    return {
      json: await stream.json().catch(err => false),
      status: stream.status,
      stream,
    }
  }
};

const errorHandlingWrapper = (promise, url, defaultError, format = true) => {
  if (url.includes('resetPassword')) {
    return promise.then(async (response) => {
      if (response.status > 299) {
        let errorMessage = defaultError || "An error occurred";
        try {
          const json = await response.json();
          errorMessage = json.error || json.message || defaultError;
        } catch (e) {
          console.error("Error on Handling JSON response:", e);
        }
        return Promise.reject({ status: response.status, message: errorMessage });
      }
      return response;
    }).catch(async error => {
      return error;
    });
  } else if (url !== '/statistics/status') {
    return promise
      .then(async stream => {
        const readOnlyLocationHeader = stream.headers.get('Access-Control-Read-Only-Location');
        if (stream.status >= 300 && stream.status < 400 && readOnlyLocationHeader) {
          // TODO: Switch this langId to actual language
          return { redirect: readOnlyLocationHeader };
        } else if (stream.status < 200 || stream.status > 299) {
          let error = (stream instanceof Response)
            ? JSON.stringify(await stream.json())
            : stream;
          throw new Error(error);
        } else {
          return stream;
        }
      })
      .then(format ? formatFetch : (stream) => stream)
      .catch(async response => {
        let error = null;
        let status = null;

        try {
          const errorObject = JSON.parse(response.message);
          error = errorObject.message;
          status = errorObject.code;
        } catch (e) {
          error = response;
          status = 404;
        }

        if (defaultError) {
          window.location.href = `/en/error?status=${status}&message=${error}`;
        } else {
          return { error, status }
        }
      });
  } else {
    return promise.then(formatFetch);
  }
};

export const get = (url, extras = {}, defaultError = true) => {
  let params = '';
  if (extras.params) params = qs.stringify(extras.params);

  delete extras.params;

  extrify(extras);
  return errorHandlingWrapper(
    fetch(getLsURL(url) + '?' + params, {
      method: 'GET',
      ...extras,
      credentials: 'include',
    }),
    url,
    defaultError
  );
};

export const getRaw = (url, extras = {}, defaultError = true) => {
  let params = '';
  if (extras.params) params = qs.stringify(extras.params);

  delete extras.params;

  extrify(extras);

  return errorHandlingWrapper(
    fetch(getLsURL(url) + '?' + params, {
      method: 'GET',
      ...extras,
      credentials: 'include',
    }),
    url,
    defaultError,
    false
  );
};

// params will be body
// use queryString instead of you want it as a query string
export const post = (url, extras = {}, defaultError = true) => {
  let queryString = '';
  if (extras.queryString) queryString = qs.stringify(extras.queryString);

  delete extras.queryString;

  extrify(extras);
  return errorHandlingWrapper(
    fetch(getLsURL(url) + '?' + queryString, {
      method: 'POST',
      ...extras,
      mode: 'cors',
      credentials: 'include',
    }),
    url,
    defaultError
  );
};

export const del = (url, extras = {}, defaultError = true) => {
  let queryString = '';
  if (extras.queryString) queryString = qs.stringify(extras.queryString);

  delete extras.queryString;

  extrify(extras);

  return errorHandlingWrapper(
    fetch(getLsURL(url) + '?' + queryString, {
      method: 'DELETE',
      ...extras,
      mode: 'cors',
      credentials: 'include',
    }),
    url,
    defaultError
  );
};

export const put = (url, extras = {}, defaultError = true) => {
  let queryString = '';
  if (extras.queryString) queryString = qs.stringify(extras.queryString);

  delete extras.queryString;

  extrify(extras);

  return errorHandlingWrapper(
    fetch(getLsURL(url) + '?' + queryString, {
      method: 'PUT',
      ...extras,
      mode: 'cors',
      credentials: 'include',
    }),
    url,
    defaultError
  );
};

const extrify = extras => {
  if (extras.params) {
    extras.body = JSON.stringify({ ...extras.params });
    delete extras.params;
  }
  return extras;
};
