import React from 'react';
import Product from './Product';
import { spacing } from '../../../../../defaults/styles';
import { ShoppingListContainer } from '../../../../smart';
// Product Page Product lists
const List = ({
  products,
  isMobile,
  linkToAdd = false,
  linkToAddLabel = null,
  noJustifyContent = null,
  loggedInHome,
  currentQuery = null,
  shoppingLists,
  cart,
  showPopup,
  setShowPopup,
  editUrl,
  viewUrl,
  setEditUrl,
  setViewUrl,
  zoomStudioUserToken,
  zoomStudioItToken,
  errorMessage,
  setErrorMessage,
  appendErrorMessage,
}) => {
  return (
    <ul
      style={
        isMobile
          ? {
              listStyle: 'none',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, 160px)',
              gridGap: spacing.verySmall,
              justifyContent: 'space-between',
              marginTop: spacing.veryLarge,
              paddingRight: 10,
              paddingLeft: 10,
            }
          : {
              listStyle: 'none',
              display: 'grid',
              gridTemplateColumns: !loggedInHome
                ? 'repeat(auto-fill, 220px)'
                : 'repeat(auto-fill, 230px)',
              gridGap: spacing.small,
              justifyContent: noJustifyContent ? null : 'space-between',
            }
      }
    >
      {products.length > 0 &&
        products.map(product => (
          <li
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: '0 0 auto',
            }}
            key={product.sku}
          >
            <ShoppingListContainer>
              <Product
                product={product}
                isMobile={isMobile}
                linkToAdd={linkToAdd}
                linkToAddLabel={linkToAddLabel}
                loggedInHome={loggedInHome}
                currentQuery={currentQuery}
                showPopup={showPopup}
                setShowPopup={setShowPopup}
                editUrl={editUrl}
                viewUrl={viewUrl}
                setEditUrl={setEditUrl}
                setViewUrl={setViewUrl}
                zoomStudioUserToken={zoomStudioUserToken}
                zoomStudioItToken={zoomStudioItToken}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                appendErrorMessage={appendErrorMessage}
                shoppingLists={shoppingLists}
              />
            </ShoppingListContainer>
          </li>
        ))}
    </ul>
  );
};

export default List;
