import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import UIContainer from './../../interface/UIContainer';
import Button from './../../interface/Button';
import ErrorMessage from './ErrorMessage';
import SuccessMessage from './SuccessMessage';
import { faKey } from '@fortawesome/pro-solid-svg-icons';
import { fonts, spacing } from '../../../../defaults/styles';

import { Translate, withLocalize } from 'react-localize-redux';

import { resetPassword, verifyPasswordResetToken } from '../../../../tools/api/authenticate';
import { getCAPTCHAKey } from '../../../../tools/helpers/recaptcha';
import ReCAPTCHA from 'react-google-recaptcha';
import { InputField } from './InputField';

const Form = styled.form`
  width: 50%;
  min-width: 300px;
  display: block;
  margin: ${spacing.small * 2}px auto;
  padding: ${spacing.medium}px ${spacing.medium}px 0 ${spacing.medium}px;
`;

const Paragraph = styled.p`
  font-size: ${fonts.sizes.subHeading}px;
  font-weight: ${fonts.weights.title};
  margin-bottom: ${spacing.large}px;
  text-align: 'center';
`;

const InputContainer = styled.div`
  margin-bottom: ${spacing.medium}px;
  position: relative;
`;

const ReCAPTCHAWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: ${spacing.medium}px;
`;

const RequestFormPasswordReset = ({ activeLanguage, isMobile, isTablet, translate, sessionId }) => {
  const t = (tag) => `resetPassword.main.${tag}`;

  const lang = activeLanguage && activeLanguage.code === 'fr' ? 'fr-CA' : 'en';

  const [isUrlVerified, setIsUrlVerified] = useState(false);
  const [clientEmail, setClientEmail] = useState('');

  useEffect(() => {
    const verifyUrl = async () => {
      try {
        verifyPasswordResetToken(sessionId)
        .then(({status, json}) => {
          if (status < 200 || status > 202) 
          return setValues({ error: true });
  
          setIsUrlVerified(true);
          setClientEmail(json.email);
        })
        .catch(error => {
          setValues({ error: true });
        });
      } catch (error) {
        setValues({ error: true });
      }
    };

    verifyUrl();
  }, []);

  // state for the form
  const [form, setValues] = useState(() => ({
    newPassword: '',
    confirmPassword: '',
    error: false,
    success: false,
    showNewPassword: false,
    showConfirmPassword: false
  }));

  const handleSubmit = e => {
    e.preventDefault();

    if (!form.newPassword || !form.confirmPassword || !form.captcha) {
      return setValues({ ...form, error: 'missingFields' });
    };

    if (form.newPassword !== form.confirmPassword) {
      return setValues({ ...form, error: 'passwordMismatch' });
    }

    const passwordRegex = new RegExp("^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?!.*[\\s|\\\\/%*=:])(?=.*[~!@#&$^()_+\\[\\]{};',.<>?-]).{8,}$");

		if(!passwordRegex.test(form.newPassword)) {
			return setValues({ ...form, error: 'weakPassword' });
		}

    resetPassword(clientEmail, form.newPassword, form.captcha, sessionId)
      .then((json) => {
        if (json.status < 200 || json.status > 202) {
          let errorMessage = 'resetFailed';

          try {
            if (json.status === 409) {
              errorMessage = 'usedPassword';
            } else {
              errorMessage = json.error || json.message || 'resetFailed';
            }
          } catch (e) {
            console.error("Error parsing JSON response:", e);
          }
          return setValues({ ...form, error: errorMessage });
        }
        setValues({ ...form, error: null, success: true });
      })
      .catch(async error => {
        let errorMessage = 'resetFailed';
        if (error.response) {
          try {
            const json = await error.response.json();
            if (json.status === 409) {
              errorMessage = 'usedPassword';
            } else {
              errorMessage = json.error || 'resetFailed';
            }
          } catch (e) {
            console.error("Error parsing JSON:", e);
          }
        }
        setValues({ ...form, error: errorMessage });
      });
  };

  const handleUpdate = e => {
    setValues({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onReCAPTCHAChange = (value) => {
    setValues({
      ...form,
      captcha: value
    });
  }

  const toggleShowNewPassword = () => {
    setValues({
      ...form,
      showNewPassword: !form.showNewPassword,
    });
  };

  const toggleShowConfirmPassword = () => {
    setValues({
      ...form,
      showConfirmPassword: !form.showConfirmPassword,
    });
  };

  return (
    <UIContainer isMobile={isMobile} isTablet={isTablet}>
        {/* <> */}
          <Form onSubmit={handleSubmit}>
          {/* session validated */}
          {isUrlVerified === true && !form.success && (
            <>
            <Paragraph>
              <Translate id={t('title')} />
            </Paragraph>
            <InputContainer>
              <InputField
                id={'newPassword'}
                form={form}
                autoFocus={true}
                faIcon={faKey}
                lexiconId={t('newPassword')}
                onChange={handleUpdate}
                onToggleNewPassword={toggleShowNewPassword}
                type={form.showNewPassword ? 'text' : 'password'}
                required
                styleLoginReg={true}
              />
              <InputField
                id={'confirmPassword'}
                form={form}
                faIcon={faKey}
                lexiconId={t('confirmPassword')}
                onChange={handleUpdate}
                onToggleConfirmPassword={toggleShowConfirmPassword}
                type={form.showConfirmPassword ? 'text' : 'password'}
                required
                styleLoginReg={true}
              />
            </InputContainer>

            {!form.success && (
              <>
                <ReCAPTCHAWrapper>
                  <ReCAPTCHA
                    sitekey={getCAPTCHAKey()}
                    onChange={onReCAPTCHAChange}
                    hl={lang}
                  />
                </ReCAPTCHAWrapper>
                <Button
                  children={translate(t('submitButton'))}
                  borderRadius={3}
                  fill={true}
                  size={16}
                  fullWidth={true}
                />
              </>
            )}
            </>
          )}
            {isUrlVerified === true && form.error && <ErrorMessage error={form.error} />}
            {isUrlVerified === true && form.success && <SuccessMessage />}
          </Form>
    </UIContainer>
  );
};

export default withLocalize(RequestFormPasswordReset);
