import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { loadOrders } from '../../../redux/actions/orderActions';
import qs from 'querystring';
import { withLocalize } from 'react-localize-redux';

export const OrdersContainer = ({
  activeLanguage,
  loadOrders,
  orders,
  history,
  apiCallsInProgress,
  totalElements,
  totalPages,
  ...props
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);  // Track if orders are loaded

  useEffect(() => {
    const param = qs.parse(history.location.search.substr(1));
    param.language = activeLanguage?.code || 'en';

    // Only call `loadOrders` if it hasn't been called yet with the current parameters
    if (!hasLoaded) {
      loadOrders(param, 0);
      setHasLoaded(true);
    }
  }, [activeLanguage, history.location.search, loadOrders, hasLoaded, apiCallsInProgress]);
  
  const childrenWithProps = React.Children.map(props.children, child =>
      React.cloneElement(child, { apiCallsInProgress, orders, loadOrders, history, totalPages, totalElements })
  );

  return <>{childrenWithProps}</>;
};

OrdersContainer.propTypes = {
  loadOrders: propTypes.func.isRequired,
  orders: propTypes.array.isRequired,
};

function mapStateToProps({ orders, apiCallsInProgress }) {
  const { content, totalElements, pageable, totalPages } = orders;
  return {
    orders: content,
    totalElements,
    pageable,
    totalPages,
    apiCallsInProgress,
  };
}

const mapDispatchtoProps = {
  loadOrders,
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(withLocalize(OrdersContainer));
