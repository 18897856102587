import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { colors, spacing } from '../../../../../defaults/styles';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';
import PopupImage from './PopupImage';

// deactivate video for now
// import ReactPlayer from 'react-player';
// import PopupVideo from './PopupVideo';

const Img = styled.img``;

const Images = ({
  activeLanguage,
  product: { imageUrl, name, additionalMedias, brand, brandImageUrl, brandId, isCanadian },
  isMobile,
  isTablet,
  translate
}) => {
  const lang = activeLanguage && activeLanguage.code === 'fr' ? 'fr' : 'en';
  const t = (tag) => `productList.${tag}`;

  const [currentImagePath, setCurrentImagePath] = useState(imageUrl);
  const [showPopup, setShowPopup] = useState(false);
  // deactivate video for now
  // const [showPopupVideo, setShowPopupVideo] = useState(false);

  useEffect(() => {
    setCurrentImagePath(imageUrl);
  }, [imageUrl]);

  const additionalImages =
    additionalMedias && additionalMedias.filter(media => media.typeId !== 2);

  return (
    <section style={{ position: 'relative' }}>
      <ProductImage
        src={currentImagePath}
        alt={name}
        onClick={() => {
          setShowPopup(true);
          // setShowPopupVideo(true);
        }}
        lang={lang}
      />
      {isCanadian && <CanadaLogoBox hasAdditionalImages={additionalImages && additionalImages.length > 0}>
        <Tooltip className="tooltip">{translate(t('canadaBrand'))}</Tooltip>
        <MapleLeafImage src={'/images/maple_leaf_transparent.png'} alt={'Canada Leaf'} lang={lang} />
      </CanadaLogoBox>}
      {additionalImages && additionalImages.length > 0 && (
        <AdditionalImageList>
          {[{ pathName: imageUrl }, ...additionalImages].map(
            ({ pathName }, index) => (
              <AdditionalImageListItem
                key={index}
                onClick={() => setCurrentImagePath(pathName)}
              >
                <AdditionalProductImage
                  pathName={pathName}
                  currentImagePath={currentImagePath}
                  src={pathName}
                  alt="Additional Media"
                  lang={lang}
                />
              </AdditionalImageListItem>
            )
          )}
        </AdditionalImageList>
      )}
      <LogoImageBox>
        <Link to={`/${lang}/catalog?brand=${brandId}`} title={''}>
          <LogoImage src={brandImageUrl} alt={brand + 'logo'} lang={lang}/>
        </Link>
      </LogoImageBox>
      {showPopup && (
        <PopupImage
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          isMobile={isMobile}
          isTablet={isTablet}
        >
          <Img src={currentImagePath} alt={'original image of' + name} lang={lang}/>
        </PopupImage>
      )}
      {/* deactivate video for now 
      {showPopupVideo && (
        <PopupVideo
          showPopupVideo={showPopupVideo}
          setShowPopupVideo={setShowPopupVideo}
          isMobile={isMobile}
          isTablet={isTablet}
        />
      )} */}
      
    </section>
  );
};

export default withLocalize(withRouter(Images));

const ProductImage = styled.img`
  border: 1px solid ${colors.gray.light};
  cursor: pointer;
  display: block;
  margin-bottom: ${spacing.small}px;
  width: 100%;
`;

const AdditionalImageList = styled.ul`
  cursor: pointer;
  list-style: none;
  padding-bottom: ${spacing.small}px;
  display: grid;
  grid-gap: ${spacing.small}px;
  grid-template-columns: repeat(auto-fill, 72px);
  justify-content: space-between;
`;

const AdditionalImageListItem = styled.li`
  cursor: pointer;
`;

const AdditionalProductImage = styled.img`
  display: block;
  border: 1px solid
    ${props =>
      props.currentImagePath === props.pathName
        ? colors.main.normal
        : colors.gray.light};
  width: 72px;
  height: 72px;
`;

const LogoImageBox = styled.div`
  border: 1px solid ${colors.gray.light};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  position: absolute;
  top: 0;
  right: 0;
`;
const LogoImage = styled.img`
  display: block;
  height: auto;
  width: 100%;
  background-color: white;
  cursor: pointer;
`;

const CanadaLogoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  position: absolute;
  bottom: 5px;
  right: 0;
  margin-bottom: ${(props) => (props.hasAdditionalImages ? '96px' : '0')};

  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }
`;

const MapleLeafImage = styled.img`
  display: block;
  height: auto;
  width: 100%;
  cursor: pointer;
`;

const Tooltip = styled.div`
  position: absolute;
  bottom: 110%;
  right: 50%;
  transform: translateX(50%);
  background-color: black;
  color: white;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: ${spacing.small}px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
  z-index: 10;
`;