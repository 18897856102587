import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import MainHeader from '../components/presentation/layout/MainHeader';
import MainFooter from '../components/presentation/layout/MainFooter';
import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import {
  HomePage,
  AboutUsPage,
  ServicesRewardsPage,
  ServicesFulfilmentPage,
  ServicesGiftingPage,
  OrdersPage,
  EnvironmentPage,
  CheckoutPage,
  ProductPage,
  BrandsPage,
  LoginPage,
  ContactUsPage,
  ContactBriefPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  PrivacyPage,
  TermsAndConditionsPage,
  LogoutPage,
  LoggedInHomePage,
  UserInfoPage,
  CatalogPage,
  BlogPostsPage,
  BlogPost,
  NewsEntriesPage,
  AccountRequestPage,
  BrandPartnersPage,
  ThankYouPage,
  CorporateGiftsPage,
  VendorAgreementPage,
  VendorAgreementSuccessPage,
  BrandRedirect,
  ErrorPage,
} from '../components/presentation/pages';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize } from 'react-localize-redux';
import ResizeAwareContainer from '../components/smart/ResizeContext';
import globalLexicon from './global-lexicon';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { lsStyled as styled } from './../tools/helpers/lsStyled';

const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
    body {
        font-family: 'Roboto', sans-serif;
    }
`;

const App = ({
  user: { loggedIn },
  location,
  activeLanguage,
  setActiveLanguage,
  initialize
}) => {

  // Initialize translations and set active language
  const isInitialized = useRef(false);

  useEffect(() => {
    if (!isInitialized.current) {
      initialize({
        languages: [
          { name: 'English', code: 'en' },
          { name: 'Français', code: 'fr' },
        ],
        translation: globalLexicon,
        options: { renderToStaticMarkup },
      });
  
      isInitialized.current = true;
    }
  }, []);

  // Use URL as a Primary Language Source
  const getInitialLang = () => {
    const langFromURL = location.pathname.split('/')[1];
    const storageLocale = localStorage.getItem('locale');
    return ['en', 'fr'].includes(langFromURL)
      ? langFromURL
      : storageLocale || (navigator.language.startsWith('fr') ? 'fr' : 'en');
  };

  useEffect(() => {
    if (!isInitialized) return;
    const lang = getInitialLang();
    if (!activeLanguage || activeLanguage.code !== lang) {
      setActiveLanguage(lang);
    }

    // Store language in localStorage for persistence
    localStorage.setItem('locale', lang);
  }, [location.pathname, activeLanguage]);

  // Prevent frequent updates in render
  const isLoginPage = useMemo(
    () => window.location.href.includes('login'),
    []);

  return (
    activeLanguage ?
    <ResizeAwareContainer>
      <GlobalStyle />

      {!isLoginPage && <MainHeader />}

      <AnimationWrapper>
        <TransitionGroup>
          <CSSTransition
            key={location.key || location.pathname}
            timeout={{ enter: 300, exit: 150 }}
            classNames={'fade'}
          >
            <Switch location={location}>
              <Route exact path='/earthfriendly'>
                <Redirect to='/en/environment' />
              </Route>
              <Route exact path='/amidelaplanete'>
                <Redirect to='/fr/environment' />
              </Route>
              <Route exact path={['/en', '/fr']} component={loggedIn ? LoggedInHomePage : HomePage} />

              {/* Handle language-specific routes */}
              <Route path={['/en/*', '/fr/*']} render={({ match }) => {
                // If you add a new route, you also have to add its name in the validPaths array, else it will redirected to the homepage
                const validPaths = [
                  "info",
                  "about-us",
                  "services-rewards",
                  "services-fulfilment",
                  "services-gifting",
                  "brands",
                  "catalog",
                  "product",
                  "blog-list",
                  "news-list",
                  "blog",
                  "news",
                  "orders",
                  "checkout",
                  "environment",
                  "login",
                  "logout",
                  "contact-us",
                  "contact-brief",
                  "account-request",
                  "brand-partners",
                  "thank-you",
                  "corporate-gifts",
                  "vendor-agreement",
                  "vendor-agreement-success",
                  "forgot-password",
                  "reset-password",
                  "privacy-policy",
                  "terms-and-conditions",
                  "error"
                ];

                const subPath = match.params[0].split('/')[0];
                const langPrefix = match.params.lang;

                if (!validPaths.includes(subPath)) {
                  return <Redirect to={`/${langPrefix}`} />;
                }

                // Render the appropriate component for valid paths
                return (
                  <>
                    <Switch>
                      <Route path={`/:lang/info`} component={loggedIn ? UserInfoPage : HomePage} />
                      <Route path={`/:lang/about-us`} component={AboutUsPage} />
                      <Route path={`/:lang/services-rewards`} component={ServicesRewardsPage} />
                      <Route path={`/:lang/services-fulfilment`} component={ServicesFulfilmentPage} />
                      <Route path={`/:lang/services-gifting`} component={ServicesGiftingPage} />
                      <Route exact path={`/:lang/brands`} component={BrandsPage} />
                      <Route path={`/:lang/catalog`} component={CatalogPage} />
                      <Route
                        path="/:lang/product/:name/:sku?"
                        render={props => {
                          const { match } = props;
                          return match.params.sku ? (
                            <ProductPage {...props} />
                          ) : (
                            <Redirect to={`/${langPrefix}`} />
                          );
                        }}
                      />
                      <Route path={`/:lang/blog-list`} component={BlogPostsPage} />
                      <Route path={`/:lang/news-list`} component={NewsEntriesPage} />
                      <Route
                        path="/:lang/blog/:title?"
                        render={props => {
                          const { match } = props;
                          return match.params.title ? (
                            <BlogPost {...props} />
                          ) : (
                            <Redirect to={`/${langPrefix}`} />
                          );
                        }}
                      />
                      <Route path={`/:lang/orders`} component={loggedIn ? OrdersPage : LoginPage} />
                      <Route path={`/:lang/checkout`} component={loggedIn ? CheckoutPage : LoginPage} />
                      <Route path={`/:lang/environment`} component={EnvironmentPage} />
                      <Route path={`/:lang/login`} component={loggedIn ? LoggedInHomePage : LoginPage} />
                      <Route path={`/:lang/logout`} component={loggedIn ? LogoutPage : HomePage} />
                      <Route path={`/:lang/contact-us`} component={ContactUsPage} />
                      <Route path={`/:lang/contact-brief`} component={ContactBriefPage} />
                      <Route path={`/:lang/account-request`} component={AccountRequestPage} />
                      <Route path={`/:lang/brand-partners`} component={BrandPartnersPage} />
                      <Route path={`/:lang/thank-you`} component={ThankYouPage} />
                      <Route path={`/:lang/corporate-gifts`} component={CorporateGiftsPage} />
                      <Route
                        path="/:lang/vendor-agreement/:token?"
                        render={props => {
                          const { match } = props;
                          return match.params.token ? (
                            <VendorAgreementPage {...props} />
                          ) : (
                            <Redirect to={`/${langPrefix}`} />
                          );
                        }}
                      />
                      <Route path={`/:lang/vendor-agreement-success`} component={VendorAgreementSuccessPage} />
                      <Route path={`/:lang/forgot-password`} component={ForgotPasswordPage} />
                      <Route path={`/:lang/reset-password`} component={ResetPasswordPage} />
                      <Route path={`/:lang/privacy-policy`} component={PrivacyPage} />
                      <Route path={`/:lang/terms-and-conditions`} component={TermsAndConditionsPage} />
                      <Route
                        path="/:lang/brands/:brandname?"
                        render={props => {
                          const { match } = props;
                          return match.params.brandname ? (
                            <BrandRedirect {...props} />
                          ) : (
                            <Redirect to={`/${langPrefix}/brands`} />
                          );
                        }}
                      />
                      <Route path={`/:lang/error`} component={ErrorPage} />
                    </Switch>
                  </>
                );
              }} />

              {/* Fallback route: redirect to homepage for any unmatched route */}
              <Route
                path="*"
                render={() => {
                  const selectedLang = localStorage.getItem('locale') || 'en';
                  return <Redirect to={`/${selectedLang}`} />;
                }}
              />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </AnimationWrapper>

      {!isLoginPage && <MainFooter />}
      <ToastContainer autoClose={3000} hideProgressBar />
    </ResizeAwareContainer>
    : null
  );
};

const AnimationWrapper = styled.div`
  .fade-appear,
  .fade-enter {
    opacity: 0;
    z-index: 1;
  }
  .fade-appear-active,
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms linear;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 150ms linear;
  }
`;

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, null)(withLocalize(withRouter(App)));
